import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
import GiftCard from '../components/GiftCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "amazonギフト券によるサポート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#amazon%E3%82%AE%E3%83%95%E3%83%88%E5%88%B8%E3%81%AB%E3%82%88%E3%82%8B%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88",
        "aria-label": "amazonギフト券によるサポート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Amazonギフト券によるサポート`}</h1>
    <p>{`「ムチでメモ」の記事を気に入っていただけたら、ジーナをサポートしてみませんか？`}</p>
    <p>{`サポートはAmazonギフト券にて `}<strong parentName="p">{`15円`}</strong>{` から受け付けています。`}<br parentName="p"></br>{`
`}{`みなさまからのサポートは、M/Mロマンス小説の購入、及び継続的なコンテンツ作成に使わせていただきます。`}</p>
    <h2 {...{
      "id": "サポートの詳細な方法",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%AE%E8%A9%B3%E7%B4%B0%E3%81%AA%E6%96%B9%E6%B3%95",
        "aria-label": "サポートの詳細な方法 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポートの詳細な方法`}</h2>
    <GiftCard mdxType="GiftCard" />
    <ol>
      <li parentName="ol">{`上の「コピー」ボタンをクリックし、メールアドレスをクリップボードにコピーする。`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.amazon.co.jp/dp/B06X982RQ9/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Amazonギフト券でサポート`}</a>{`ボタンから、アマゾンの商品ページへ移動する。`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "120%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAYABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAe7MZmtwuZBQP//EABgQAAIDAAAAAAAAAAAAAAAAAAAhEBEg/9oACAEBAAEFAhxbz//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/ASP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAACAwAAAAAAAAAAAAAAAAAAMQEQIP/aAAgBAQAGPwJjqdf/xAAZEAEBAQEBAQAAAAAAAAAAAAABABFRIRD/2gAIAQEAAT8h91yJ0idBtsScPYOh8//aAAwDAQACAAMAAAAQbAd8/8QAFxEBAQEBAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAZ2bP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhMUEQUXHR4f/aAAgBAQABPxBXiK4qNSqK4rcCGVfMuNbp+QGrb4zBRiZVC3cxPSDp/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5ebf44103b6f394876bfed6601d92b25/cb58d/amazon-gift-card.webp 300w", "/static/5ebf44103b6f394876bfed6601d92b25/f8b1b/amazon-gift-card.webp 600w", "/static/5ebf44103b6f394876bfed6601d92b25/03f31/amazon-gift-card.webp 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/5ebf44103b6f394876bfed6601d92b25/37e03/amazon-gift-card.jpg 300w", "/static/5ebf44103b6f394876bfed6601d92b25/2a8be/amazon-gift-card.jpg 600w", "/static/5ebf44103b6f394876bfed6601d92b25/dbdff/amazon-gift-card.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5ebf44103b6f394876bfed6601d92b25/dbdff/amazon-gift-card.jpg",
            "alt": "イメージ画像",
            "title": "イメージ画像",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`金額（15円から）を入力する。`}</li>
      <li parentName="ol">{`「受取人」に、コピーしたメールアドレス(`}<a parentName="li" {...{
          "href": "mailto:gina@muchimemo.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`gina@muchimemo.com`}</a>{`)を入力する。`}</li>
      <li parentName="ol">{`「贈り主」に、何でもいいので本名ではないお名前を入力してください。`}</li>
    </ol>
    <p>{`※「メッセージ」は空でも構いませんが、メッセージがあると嬉しいです。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      